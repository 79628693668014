<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        defaultActiveKey="0"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane key="0" :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button" @click="exportForm" v-if="isView">
            <img class="export" :src="exportImage" alt="" />
            <span>导出文档</span>
          </div>
          <div class="button" @click="download">
            <img class="download" :src="downloadImage" alt="" />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="当前项目" class="default-highlight">
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目资金审计原则">
              <a-radio-group
                v-decorator="[
                  'isAudit',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择',
                      },
                    ],
                  },
                ]"
              >
                <a-radio :value="1"> 符合 </a-radio>
                <a-radio :value="0"> 不符合 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="工程名称"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              class="default-highlight"
            >
              <a-input :disabled="true" :placeholder="projectName" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="调整金额(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                style="width: 100%"
                v-decorator="[
                  'modifyAmount',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              ></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="调整利润(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                style="width: 100%"
                v-decorator="[
                  'modifyProfit',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              ></a-input-number>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="项目采购计划调整概述"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 5 }"
                v-decorator="[
                  'modifyPlanDesc',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
                placeholder="(调整原因、涉及金额、是否满足审计结算要求等)"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label">附件</span>
              <div
                class="link-list"
                v-decorator="[
                  'file',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请上传附件！',
                      },
                    ],
                  },
                ]"
              >
                <a-checkbox-group
                  :value="selectedFileList"
                  @change="onSelectFile"
                  v-if="fileList.length !== 0"
                >
                  <div class="link" v-for="item in fileList" :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank" download :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div
                  style="color: #ccc"
                  v-else-if="fileList.length === 0 && id"
                >
                  无
                </div>
                <div style="color: #ccc" v-else>
                  《总承包项目采购计划调整申请表》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control" @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" v-if="!isView"
              >提交</a-button
            >
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  name: "purchaseEmergan",
  components: {
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_purchase_emergency",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.form.setFieldsValue({
            isAudit: res.isAudit,
            modifyAmount: res.modifyAmount,
            modifyProfit: res.modifyProfit,
            modifyPlanDesc: res.modifyPlanDesc,
          });
          if (res.attachmentList) {
            this.form.setFieldsValue({
              file: res.attachmentList,
            });
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate(this.stage)
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    onSelectContract(value) {
      console.log(value.contractAmount);
      this.form.setFieldsValue({
        contractCode: value.code,
      });
      this.form.setFieldsValue({
        contractPrice: value.contractAmount * 0.0001,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
